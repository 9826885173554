* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Courier, Roboto, sans-serif;
  line-height: 1.4;
  color: #444;
  background-color: #3f9fde;
  height: 100vh;
  text-align: center;
  font-weight: bold;
}

#pinkRaffle {
  font-weight: bold;
  text-align: center;
}

#yellowRaffle {
  font-weight: bold;
  text-align: center;
}

#stake {
  font-weight: bold;
  text-align: center;
}

#srp {
  font-weight: bold;
  text-align: center;
}

#road {
  margin: auto;
  min-width: 300px;
  text-align: center;
}

li {
  list-style: none;
  font-weight: bold;
  font-size: small;
}

li span {
  padding-right: 5px;
}

#container {
  width: 80%;
  height: 100%;
  min-width: 300px;
  min-height: 800px;
  margin: auto;
  background-color: #d7d5d5;
  padding-bottom: 20px;
}

h1 {
  padding: 10px;
}

h3 {
  text-decoration: underline;
}

#content {
  margin: auto;
  min-width: 300px;
}

ul {
  text-align: left;
}

.ticket {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 70px 1fr;
  padding-left: 10px;
  margin: 10px;
}

.raffle {
  width: 64px;
  height: 32px;
}

.ticket img {
  grid-column: 1/2;
  margin-top: auto;
  margin-bottom:auto;
}

.ticket span {
  grid-column: 2/3;
  margin-top: auto;
  margin-bottom:auto;
}

.bold {
  font-weight: bold;
}

#faq {
  margin: auto;
  min-width: 300px;
  padding: 10px;
  line-height: 2em;
  font-size: small;
}

#announcement {
  border: black 2px solid;
  padding: 10px;
  border-radius: 5px;
  width: 95%;
  margin: auto;
}

#footer {
  text-align: center;
  font-size: smaller;
}

#topBar {
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  padding: 0;
}

.timeline {
  text-decoration: underline;
}

#connect {
  width: 80%;
  min-width: 300px;
  height: 40px;
  position: fixed;
  background-color: #d7d5d5;
  grid-row: 1/2;
  padding-top: 5px;
}

header {
  grid-row: 2/3;
  padding: 0;
}

.gitLink {
  width: 64px;
  height: 64px;
}

#connectButton {
  height: 30px;
  width: 110px;
  float: right;
  margin-right: 15px;
}

#connectBar {
  width: 80%;
  min-width: 300px;
  height: 40px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50%;
}

#burger {
  grid-row: 1/2;
}

#disc {
  height: 40px;
  width: 40px;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 5px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.protocol {
  width: 95%;
  margin: auto;
  padding: 5px;
  text-align: center;
  border: 2px black solid;
  border-radius: 5px;
  font-size: small;
  font-weight: bold;
}

.protocol p {
  text-align: left;
  line-height: 2em;
  padding: 5px;
}

#mint {
  width: 95%;
  margin: auto;
  padding: 5px;
  text-align: center;
  border: 2px black solid;
  border-radius: 5px;
  font-size: small;
  font-weight: bold;
  line-height: 2em;
}

#treasury {
    width: 95%;
    margin: auto;
    padding: 5px;
    text-align: center;
    border: 2px black solid;
    border-radius: 5px;
    font-size: small;
    font-weight: bold;
    line-height: 2em;
}

#mintButton {
    width: 100px;
}

#myPink {
    width: 90%;
    margin: auto;
    text-align: center;
    border: 2px black dotted;
}

#claimButton {
  width: 50px;
}

.address {
  font-size: .65em;
}

#donateButton {
  width: 75px;
}

@media only screen and (min-width: 350px) {
  .bm-burger-button {
    left: 11%;
  }

}

@media only screen and (min-width: 450px) {
  .bm-burger-button {
    left: 12%;
  }

  .address {
    font-size: small;
  }
}

@media only screen and (min-width: 550px) {
  .raffle {
    width: 128px;
    height: 64px;
  }

  .ticket {
    grid-template-columns: 140px 1fr;
  }

  li {
    font-size: large;
  }

  #faq {
    font-size: medium;
  }

  .protocol {
    font-size: medium;
  }

  #mint {
    font-size: medium;
  }

  #treasury {
    font-size: medium;
  }

  #pinkError {
    color: red;
  }

  .address {
    font-size: medium;
  }

}